$(document).ready(function() {
    // Change the start column values in posting screens
    function updateInputFields() {
        const startDate = $('#start_date_input').val();
        $('.start-date-posting-screens').text(startDate);
        $('input[name="posting[start]"]').val(startDate);
    }

    $('#start_date_input').on('input', updateInputFields);

    // When the page rerenders after error we populate the start column of posting screens with correct dates
    if ($('#start_date_input').val()) {
        updateInputFields();
    }
});

