$().ready(function () {
    $(document).on('mouseover', '.day-label', function () {
        $(this).addClass('highlit');
        $('.ds-' + $(this).data('index')).addClass('highlit');
    });

    $(document).on('mouseout', '.day-label', function () {
        $(this).removeClass('highlit');
        $('.ds-' + $(this).data('index')).removeClass('highlit');
    });
});
