// Draws the right colors onto the canvas that is used to represent a day. Currently unused (canvases replaced by divs).
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ ]

    connect() {
        // get colors from data-drawing-collors attribute
        let colors = this.data.get("colors").split(" ")

        // draw colors onto canvas
        let ctx = this.element.getContext("2d")
        colors.forEach((color, index) => {
            ctx.fillStyle = color
            ctx.fillRect(index * 3, 0, 3, 1)
        })
    }
}
