// Implements a switchable input that either shows a select or a text input for a new entry.
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "oldSelect", "newForm" ]

  disabled = false
  disabledHandler = ev => ev.preventDefault()

  connect() {
    console.log("new_client controller connected")
    $(this.oldSelectTarget).select2()

    $(this.newFormTarget).on('shown.bs.collapse hidden.bs.collapse', ()=>{
      console.log("transition end")
      this.enableInteraction()
    })
  }

  change() {
    this.checkboxTarget.addEventListener('click', this.disabledHandler)
    if (this.checkboxTarget.checked) {
      this.switchToNew()
    } else {
      this.hideNew()
    }
  }

  switchToNew () {
    $(this.newFormTarget).collapse('show')
    this.oldSelectTarget.setAttribute('disabled', 1)
  }

  hideNew() {
    $(this.newFormTarget).collapse('hide')
    this.oldSelectTarget.removeAttribute('disabled')
  }

  enableInteraction() {
    this.checkboxTarget.removeEventListener('click', this.disabledHandler)
  }
}
