$(function () {
    function updatePostData() {
        let data = $('#ssf').serializeArray();
        data = data.filter(v => v.name != '_method');
        $('.screen-select-listing').data('postData', data);
    }

    $('#campaign_file_new_campaign').on('change', function() {
        const hasFile = $(this).val() !== '';
        $('#import_campaign_button').prop('disabled', !hasFile);
    }).trigger('change');

    // # Form
    $('#start').change(function () {
        $('.start').val(this.value);

        let startDate = parseDate(this.value);
        let stopDate = parseDate($('#stop').val());

        if (stopDate == 'Invalid Date' || stopDate < startDate) {
            $('#stop').val(this.value);
            $('.stop').val(this.value);
        }

        updatePostData();
        $('.screen-select-listing').trigger('refresh');
    });
    if ($('#start').val()) $('.start').val($('#start').val());

    $('#stop').change(function () {
        $('.stop').val(this.value);

        let startDate = parseDate($('#start').val());
        let stopDate = parseDate(this.value);

        if (startDate == 'Invalid Date' || stopDate < startDate) {
            $('#start').val(this.value);
            $('.start').val(this.value);
        }

        updatePostData();
        $('.screen-select-listing').trigger('refresh');
    });
    if ($('#stop').val()) $('.stop').val($('#stop').val());

    $('#slots').change(function () {
        $('.slots').val($(this).val());
        updatePostData();
        updateAllAvailableSlotsCount();
        updateAllChosenSlotsCount();

        $('.screen-select-listing').trigger('refresh');
    });

    $('#discount').change(function () {
        $('.discount').val($(this).val());
        updatePostData();
    });

    updatePostData();

    function updateScreenCount() {
        //num of all screens
        const screenCount = $('tbody .listing-item:not(.d-none)').length;
        $('.num-all-screens').text(screenCount.toLocaleString('cs-CZ'));

        //num of selected screens
        let selectedScreenCount = $('tbody .listing-item:not(.d-none) .screen-select-checkbox:checked').length;
        $('.num-selected-screens').text(selectedScreenCount.toLocaleString('cs-CZ'));
    }

    function parseDate(dateStr) {
        const parts = dateStr.trim().split('.');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    function parseDateToIndex(dateStr) {
        const startDateTop = parseDate($('#start').val());
        const stopDateTop = parseDate($('#stop').val());
        const date = parseDate(dateStr);

        if (startDateTop == 'Invalid Date' || stopDateTop == 'Invalid Date' || date == 'Invalid Date') {
            return null; // invalid date
        }

        if (date < startDateTop) {
            return -1; // before range
        }

        return (date - startDateTop) / (24 * 3600 * 1000);
    }

    // count total clips per day where clipsPerSlot and slotsAvailable are retrieved from dsElement
    function getClipsFromDs(dsElement, maxSlots) {
        let valueParts = dsElement.textContent.trim().split(',');
        let clipsPerSlot = parseInt(valueParts[0]);
        let slotsAvailable = parseInt(valueParts[1]);
        return clipsPerSlot * Math.min(slotsAvailable, maxSlots);
    }

    function updateAllAvailableSlotsCount() {
        let totalForAllScreens = 0;
        let isIncomplete = false;

        // Map indices of selected top dates corresponding to shown .ds elements
        const startIndex = parseDateToIndex($('#start').val());
        const stopIndex = parseDateToIndex($('#stop').val());

        $('tr.listing-item').not('.d-none').each(function() {
            let $listingItem = $(this);

            // Get number of slots from input for current listing-item
            let slotsFromInput = parseInt($listingItem.find('.slots').val());

            // getting values of '.ds' only for picked dates
            for (let i = startIndex; i <= stopIndex; i++) {
                let dsElement = $listingItem.next('.no-top-border').find('.ds')[i];
                if (dsElement) {
                    totalForAllScreens += getClipsFromDs(dsElement, slotsFromInput);
                } else {
                    isIncomplete = true;
                }
            }
        });
        $('.num-all-spots').text(totalForAllScreens.toLocaleString('cs-CZ'));
        $('.num-all-spots-plus').toggle(isIncomplete);
    }

    function updateAllChosenSlotsCount() {
        let totalForAllScreens = 0
        let isIncomplete = false

        // Iterate over each selected listing-item
        $('tr.listing-item').has('.screen-select-checkbox:checked').each(function() {
            const $listingItem = $(this);

            // Map indices of selected row dates corresponding to shown .ds elements,
            // -1 is expected if date lies before shown .ds elements
            const startDateIndex = parseDateToIndex($listingItem.find('.start.date').val());
            const stopDateIndex = parseDateToIndex($listingItem.find('.stop.date').val());

            // Do not count this row if either date is empty
            if (startDateIndex === null || stopDateIndex === null) {
                return;
            }

            // Get number of slots from input for current listing-item
            let slotsFromInput = parseInt($listingItem.find('.slots').val());

            // getting values of '.ds.ds-*index*.ds-g' only for picked dates
            for (let i = startDateIndex; i <= stopDateIndex; i++) {
                let dsElement = $listingItem.next('.no-top-border').find('.ds')[i];
                if (dsElement) {
                    totalForAllScreens += getClipsFromDs(dsElement, slotsFromInput);
                } else {
                    isIncomplete = true;
                }
            }
        });

        $('.num-selected-spots').text(totalForAllScreens.toLocaleString('cs-CZ'));
        $('.num-selected-spots-plus').toggle(isIncomplete);
    }

    // # Listing
    $('.screen-select-listing').on('load.screen-select update.listpress', function () {
        updateScreenCount();
        updateAllAvailableSlotsCount();
        updateAllChosenSlotsCount();

        // ## date fields for each screen
        $('.start.date, .stop.date').on('change', function() {
            updateAllChosenSlotsCount();
        });

        // ## screen select checkbox
        $('.screen-select-checkbox-all').click(function () {
            $('.screen-select-checkbox:visible').prop('checked', this.checked);
            $('.screen-select-submit').attr('disabled', $('.screen-select-checkbox:checked').length < 1);
            updateScreenCount();
            updateAllAvailableSlotsCount();
        });

        $('.screen-select-checkbox').on('click update.screen-select', function () {
            $('.screen-select-submit').attr('disabled', $('.screen-select-checkbox:checked').length < 1);
            updateScreenCount();
        }).trigger('update.screen-select');

        // ## toggle exact time
        $('.exact-time').on('click update.screen-select', function () {
            $(this).closest('tr').find('.slots').toggle(!this.checked);
            $(this).closest('tr').find('.time').toggle(this.checked);
            if (!this.checked) $(this).closest('tr').find('.time').val("");
        }).trigger('update.screen-select');

        // ## update data do be POSTed when listing is being updated
        $('.screen-select-listing').find('select, input')
            .on('change', updatePostData)
            .on('change', updateAllAvailableSlotsCount)
            .on('change', updateAllChosenSlotsCount);

    }).trigger('load.screen-select');
});
