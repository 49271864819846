import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        this.table = $(this.element)
        this.sort_column = null
        this.sort_direction = null
        this.sort_type = "string"
        this.sort_selector = this.element.dataset['sortSelector'] || "tbody tr"

        let self = this;

        self.table.find('.sortable-label').each(function () {
            $(this).append(' <i class="sortable-arrow fa fa-sort"></i>')
        }).click(function () {
            var column = this.dataset["column"]
            if (column == self.sort_column) {
                self.sort_direction = self.sort_direction == 'asc' ? 'desc' : 'asc'
            } else {
                self.sort_direction = 'asc'
                self.sort_column = column
            }
            self.sort_type = this.dataset['type'] || "string"

            self.table.find('.sortable-arrow').removeClass('fa-sort-up fa-sort-down').addClass('fa-sort')
            $(this).find('.sortable-arrow').removeClass('fa-sort').addClass(self.sort_direction == 'asc' ? 'fa-sort-down' : 'fa-sort-up')
            self.sort()
        })
    }

    sort() {
        if (!this.sort_column || !this.sort_direction) return

        let rows = this.table.find(this.sort_selector).detach()
        let dir = this.sort_direction == 'asc' ? 1 : -1

        rows.sort((a, b) => {
            a = this.extract_value(a)
            b = this.extract_value(b)

            return dir * this.cmp(a, b)
        })

        this.table.append(rows)
    }

    cmp(a, b) {
        if (typeof a == "string" && typeof b == "string") return a.localeCompare(b, "cs")
        if (a === null && b === null) return 0
        if (b === null) return -1
        if (a === null) return 1
        if (a < b) return -1
        if (a > b) return 1
        return 0
    }

    extract_value(row) {
        let self = this
        let value = null

        $(row).find('.sortable-value').each(function () {
            if (this.dataset['column'] == self.sort_column) {
                if ($(this).is('input[type=checkbox]')) {
                    value = this.checked ? 1 : 0
                } else if ($(this).is('input, select')) {
                    value = $(this).val()
                } else {
                    value = $(this).text()
                }
            }
        })

        if (value === null || value === "") return null

        if (self.sort_type == "number") {
            value = parseFloat(value.replaceAll(",", ".").replaceAll(/\s/g, ""))
        } else if (self.sort_type == "date") {
            value = value.split('.').reverse().map(i => i.replaceAll(/\s/g, "").padStart(4, "0")).join('-')
        }

        return value
    }
}
