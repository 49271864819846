import {Controller} from "stimulus"

export default class extends Controller {
    static targets = [
        "slotsMainInput", "startMainInput", "stopMainInput", "fixateMainInput", "autoGroupMainInput", "groupMainInput", "discountMainInput", "destroyMainInput", "selectMainInput", "motifMainInput", "clipsMainInput",
        "slotsShowingInput", "startShowingInput", "stopShowingInput", "fixateShowingInput", "spotPriceMainInput", "spotPriceShowingInput", "autoGroupShowingInput", "groupShowingInput", "discountShowingInput", "destroyShowingInput", "selectShowingInput", "motifShowingInput", "clipsShowingInput"
    ]

    connect() {
        console.log(`showings mass change with slots input ${this.slotsMainInputTarget}`)
    }

    rewriteSlots() {
        const value = this.slotsMainInputTarget.value
        console.log(`updating slots with value: ${value}`)
        const targets = this.filterInputsBasedOnSelection(this.slotsShowingInputTargets)
        targets.forEach(input => {input.value = value })
    }

    rewriteStart() {
        const value = this.startMainInputTarget.value
        console.log(`updating start with value: ${value}`)
        const targets = this.filterInputsBasedOnSelection(this.startShowingInputTargets)
        targets.forEach(input => {input.value = value })
    }

    rewriteStop() {
        const value = this.stopMainInputTarget.value
        console.log(`updating stop with value: ${value}`)
        const targets = this.filterInputsBasedOnSelection(this.stopShowingInputTargets)
        targets.forEach(input => {input.value = value })
    }

    rewriteFixate() {
        const checked = this.fixateMainInputTarget.checked
        console.log(`updating fixate with checked: ${checked}`)
        const targets = this.filterInputsBasedOnSelection(this.fixateShowingInputTargets)
        targets.forEach(input => {input.checked = checked})
    }

    rewriteSpotPrice() {
        const value = this.spotPriceMainInputTarget.value
        console.log(`updating spot price with value: ${value}`)
        const targets = this.filterInputsBasedOnSelection(this.spotPriceShowingInputTargets)

        targets.forEach(input => {input.value = value})
        $(".spot_price_input").change()
    }

    rewriteAutoGroup() {
        const checked = this.autoGroupMainInputTarget.checked
        console.log(`updating group with checked: ${checked}`)

        // we need to manually fire the change event, because another handling
        // is being done by the screen-group-select controller (disabling and
        // enabling the group select)
        const event = new Event('change');
        const targets = this.filterInputsBasedOnSelection(this.autoGroupShowingInputTargets)

        targets.forEach(input => {
            input.checked = checked
            input.dispatchEvent(event)
        })
    }

    rewriteGroup() {
        const selectedIndex = this.groupMainInputTarget.selectedIndex
        console.log(`updating stop with index: ${selectedIndex}`)
        const targets = this.filterInputsBasedOnSelection(this.groupShowingInputTargets)
        targets.forEach(input => {input.selectedIndex = selectedIndex })
    }

    rewriteDiscount() {
        const value = this.discountMainInputTarget.value
        console.log(`updating discount with value: ${value}`)
        const targets = this.filterInputsBasedOnSelection(this.discountShowingInputTargets)
        targets.forEach(input => {input.value = value })
    }

    rewriteDestroy() {
        const checked = this.destroyMainInputTarget.checked
        console.log(`updating destroy with checked: ${checked}`)

        // we need to manually fire the click event, because another handling
        // is being done by the highlight controller (highlight the whole row)
        const event = new Event('click');

        this.destroyShowingInputTargets.forEach(input => {
            input.checked = checked
            input.dispatchEvent(event)
        })
    }

    rewriteSelect() {
        const checked = this.selectMainInputTarget.checked
        console.log(`updating select with checked: ${checked}`)

        // we need to manually fire the click event, because another handling
        // is being done by the fading controller (fading the whole row)
        const event = new Event('click');

        this.selectShowingInputTargets.forEach(input => {
            input.checked = checked
            input.dispatchEvent(event)
        })
    }

    //posting screens
    rewriteClips() {
        const value = this.clipsMainInputTarget.value
        console.log(`updating clips with value: ${value}`)
        const targets = this.filterInputsBasedOnSelection(this.clipsShowingInputTargets)
        targets.forEach(input => {input.value = value})
    }

    rewriteMotif() {
        const value = this.motifMainInputTarget.value
        console.log(`updating motif with value: ${value}`)
        const targets = this.filterInputsBasedOnSelection(this.motifShowingInputTargets)
        targets.forEach(input => {input.value = value})
    }

    filterInputsBasedOnSelection(inputTargets) {
        const checkboxes = document.querySelectorAll('input[type="checkbox"][data-target="highlight.checkbox showings-mass-change.selectShowingInput"]');
        const selectedInputs = [];
        let anyChecked = false;

        checkboxes.forEach(checkbox => {
            if (checkbox.checked) {
                anyChecked = true;
                const row = checkbox.closest('tr');
                if (row) {
                    Array.from(row.querySelectorAll('input, select')).forEach(input => {
                        if (Array.from(inputTargets).includes(input)) {
                            selectedInputs.push(input);
                        }
                    });
                }
            }
        });

        // If there are no checkboxes checked mass change works for all fields
        return anyChecked ? selectedInputs : Array.from(inputTargets);
    }
}
