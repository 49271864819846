$().ready(function () {
    $(window).on('resize', function () {
        let $lc = $('.resizable-listing .listing-content');

        let element = $lc[0];
        let offsetTop = 0;
        while(element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }

        $lc.css('max-height', window.innerHeight - offsetTop - 28);
    }).resize();

});
