// Draws the right colors onto the canvas that is used to represent a day. Currently unused (canvases replaced by divs).
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["fdCheckbox", "button"]

    connect() {

    }

    inputsChanged() {
        this.buttonTarget.classList.remove("btn-secondary")
        this.buttonTarget.classList.add("btn-warning")
        this.buttonTarget.disabled = false
        this.buttonTarget.innerText = "Uložit"
    }

    buttonClicked() {
        this.buttonTarget.disabled = true

        const url = this.element.dataset.deploymentUpdatePath

        var formData = {
            "campaign[photo_check]": this.fdCheckboxTarget.checked,
        }

        Rails.ajax({
            url: url,
            type: 'PATCH',
            dataType: 'text/html',
            data: new URLSearchParams(formData).toString(),
            success: (data, status, xhr) => {
                console.log(status)
                this.buttonTarget.classList.remove("btn-warning")
                this.buttonTarget.classList.add("btn-success")
                this.buttonTarget.disabled = true
                this.buttonTarget.innerText = "Uloženo!"
            }
        })
    }
}
