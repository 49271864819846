import $ from "jquery"

$.fn.dropdownCheck = function (action) {
    const collator = new Intl.Collator('cs', {sensitivity: 'base', usage: 'search'});

    function contains(haystack, needle) {
        for (let i = 0; i <= haystack.length - needle.length; i++) {
            if (collator.compare(haystack.slice(i, i + needle.length), needle) === 0) {
                return true;
            }
        }
        return false;
    }

    this.each(function () {
        var $dropdown = $(this);

        if (action == "destroy") {
            $dropdown.off('.dropdownCheck');
            return
        }

        if ($dropdown.data('dropdownCheckInitialized')) return;

        function updateDropdown() {
            var $checked = $dropdown.find('input[type=checkbox]:checked');

            var count = $checked.length;
            $dropdown.find('.dropdown-toggle span.count').text(count > 0 ? "(" + count + ")" : "");

            var text = [];
            $checked.closest('.form-check').find('label').each(function () {
                text.push($(this).text());
            });

            $dropdown.find('.dropdown-toggle span.list').text(text.length ? text.join(', ') : '- Vyberte -');
        }

        $dropdown.on("click.dropdownCheck", ".dropdown-item", function (e) {
            $(this).find('input[type=checkbox]').click();
            e.stopPropagation();
            $dropdown.trigger('change.dropdownCheck');
        });

        $dropdown.on("click.dropdownCheck", "input[type=checkbox],label", function (e) {
            updateDropdown();
            e.stopPropagation();
            $dropdown.trigger('change.dropdownCheck');
        });

        $dropdown.on("click.dropdownCheck", "a.select-all", function (e) {
            var $inputs = $dropdown.find('input[type=checkbox]:visible');
            $inputs.prop('checked', $inputs.length != $inputs.filter(':checked').length);

            updateDropdown();
            $dropdown.trigger('change.dropdownCheck');
            return false;
        });

        $dropdown.on("click.dropdownCheck", "a.select-inv", function (e) {
            $dropdown.find('input[type=checkbox]:visible').each(function () {
                this.checked = !this.checked;
            });

            updateDropdown();
            $dropdown.trigger('change.dropdownCheck');
            return false;
        });

        $dropdown.on("input.dropdownCheck change.dropdownCheck", "input[type=text]", function (e) {
            let value = $(this).val();

            $dropdown.find('label').each(function () {
                $(this).closest('.dropdown-item').toggle(contains(this.textContent, value));
            });
            e.stopPropagation();
            return false;
        });
        $dropdown.find('input[type=text]').change();

        $dropdown.on("click.dropdownCheck", "a.reset", function (e) {
            $(this).closest('.input-group').find('input').val("").change();
            return false;
        });

        $dropdown.on('shown.bs.dropdown', function () {
            $dropdown.find('input[type=text]').focus();
        });

        $dropdown.data('dropdownCheckInitialized', true);

        updateDropdown();
    });


    return this;
};

$().ready(function () {
    $('.dropdown-check').dropdownCheck();
});
