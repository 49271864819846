$(document).ready(function() {
    // Update checkbox when the value of the text field changes
    $(".spot_price_input").on("change", function() {
        $(this).closest("td").find(".spot_price_manual_checkbox").prop("checked", $(this).val().length > 0).change();
    });

    $(".spot_price_manual_checkbox").on("change", function() {
        $(this).closest("td").find(".spot-price-wrapper").toggleClass("manual", this.checked).attr("title", this.checked ? "Ruční cena. Pro zrušení ruční ceny vymažte obsah políčka." : null);
    }).change();
});
