$(function () {
    // # Listing
    $('.occupancy-screen .listing').on('load.occupancies update.listpress', function (e, currentUrl) {
        if (currentUrl) {
            $('.occupancy-screen').find('.card-header a[href], .controls a[href]').each(function () {
                let href = $(this).attr('href');
                $(this).attr('href', href.split('?')[0] + '?' + currentUrl.split('?')[1]);
            });
        }

        $('.occupancy-column').on('mouseenter', function () {
            let index = $(this).index();
            $(this).addClass('grown');
            $(this).closest('td').find('.sum-row').children().eq(index).addClass('grown');
            $(this).closest('table').find('.occupancy-days .day-label').eq(index).addClass('grown');
        });

        $('.occupancy-column').on('mouseleave', function () {
            let index = $(this).index();
            $(this).removeClass('grown');
            $(this).closest('td').find('.sum-row').children().eq(index).removeClass('grown');
            $(this).closest('table').find('.occupancy-days .day-label').eq(index).removeClass('grown');
        });
    }).trigger('load.occupancies');
});
