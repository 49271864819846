// Autocompletes the "stop" date of the start+stop duo. Assumes the app-wide datepicker.
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['startInput', 'stopInput']

    connect() {
        // the datepicker used fires jquery events
        $(this.startInputTarget).on('changeDate', () => {this.startChanged()})
    }

    startChanged() {
        if (this.stopInputTarget.value === "") {
            this.stopInputTarget.value = this.startInputTarget.value
        }
    }
}
