// Used to fade (disable inputs + css fade) the whole container on which this is used.
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["checkbox", "select"]

    connect() {
    }

    change() {
        if (this.checkboxTarget.checked) {
            // disable collection select
            this.selectTarget.disabled = true
        } else {
            // enable collection select
            this.selectTarget.disabled = false
        }
    }
}
