import {updateUrlWithParams} from "listpress"

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

function formatSelect2(option) {
    var image = $(option.element).data('image');
    if (image) {
        return $('<span>' + image + ' </span>').append(option.text);
    } else {
        return option.text;
    }
}

$.fn.select2.defaults.set("templateResult", formatSelect2);
$.fn.select2.defaults.set("templateSelection", formatSelect2);
$.fn.select2.defaults.set("theme", "bootstrap");

$(function () {
    $('body').popover({
        selector: '.screen-tooltip',
        container: 'body',
        trigger: 'hover',
        html: true,
        placement: 'top',
        boundary: 'window'
    });

    setTimeout(function () {
        $('.flash.alert-success').alert('close');
    }, 3000);

    function setupSelect2(base) {
        $(base).find('select.select2-sm').select2({
            theme: "bootstrap select2-sm",
            minimumResultsForSearch: 10,
        });

        $(base).find('select.select2').select2({
            theme: "bootstrap",
            minimumResultsForSearch: 10,
        });
    }

    setupSelect2($('body'));
    $('body').on('update.listpress', '.listing', function () {
        setupSelect2(this);
    });

    $(document).off('.datepicker.data-api');
    $('body').on('focus click', '.date', function () {
        $(this).datepicker({format: 'dd. mm. yyyy', autoclose: true, todayBtn: 'linked', todayHighlight: true, language: 'cs'});
        $(this).on('changeDate', function () {
           $(this).trigger('input');
        });
    });

    $('.listing').on('update.listpress', function (e, currentUrl) {
        $('form.follow-listing').each(function () {
            this.action = updateUrlWithParams(this.action, [["back_url", currentUrl]]);
        });
        $('a.follow-listing').each(function () {
            this.href = updateUrlWithParams(this.href, [["back_url", currentUrl]]);
        });
        $('a.follow-listing-params').each(function () {
            const params = Array.from(new URL(currentUrl).searchParams.entries());
            this.href = updateUrlWithParams(this.href, params, ['list[']);
        });
        $('form.follow-listing-field').each(function () {
            $(this).find('#back_url').val(currentUrl);
        });
    });
});
